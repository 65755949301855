import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { SelectionModel } from "@angular/cdk/collections";
import { ROLES } from "src/app/core/core-services/constants/roles.constant";
import moment from "moment";
import { MatSort, SortDirection } from "@angular/material/sort";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { ClipboardService } from "ngx-clipboard";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { Subscription, Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { EditUserComponent } from "../edit-user/edit-user.component";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { SuperAdminApiService } from "../services/super-admin-api.service";
import { LICENSE_TYPES } from "src/app/core/core-services/constants/licenses.constants";
import { AdminApiService } from "../services/admin-api.service";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { NavigationService } from "src/app/core/core-services/services/navigation.service";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { ReactivateComponent } from "./reactivate/reactivate.component";
import { VengresoAddUserByEmailComponent } from "../vengreso/add-user-by-email/add-user-by-email.component";
import { VengresoAddUserManuallyComponent } from "../vengreso/add-user-manually/add-user-manually.component";
import { SUBSCRIPTION_TYPES } from "src/app/core/core-services/constants/subscriptions.constants";
import { ACCOUNT_STATUS } from "src/app/core/core-services/constants/account_status.constants";
import { UsersApiService } from "../services/users/users-api.service";
import { AssignRoleComponent } from "../vengreso/assign-role/assign-role.component";
import { ConfirmationDialogData, ConfirmationModalComponent } from "src/app/components/confirmation-modal/confirmation-modal.component";
import { AssignMoveToCompanyComponent } from "../vengreso/assign-move-to-company/assign-move-to-company.component";
import { BulkAssignMoveToCompanyComponent } from "../vengreso/bulk-assign-move-to-company/bulk-assign-move-to-company.component";

interface GroupNode {
  id: string;
  name: string;
  count: number;
  children?: GroupNode[];
}

@Component({
  selector: "app-admin-users",
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.scss"],
})
export class AdminUsersComponent implements OnInit {
  subscriptions = new Subscription();
  dateAvailable: string = moment().subtract(1, "day").format("MMM, DD YYYY");
  loadUsersTimeout: NodeJS.Timeout;
  dataSource: any;
  dialogRef: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedSort: string = "last_added";
  selectedSubscriptionTypes: string[] = [];
  selectedAccountStatus: string[] = [];
  displayedColumns: string[] = [
    "select",
    "name",
    "company",
    "licenseType",
    "status",
    "extension",
    "role",
    "actions",
  ];
  roleColors: {
    [role: string]: string;
  } = {
    "Vengreso Admin": "#d3aae5",
    "Global Admin": "#FEEFEA",
    "Group Admin": "#FEF9EA",
    "Reporting Admin": "#EEEEEE",
    User: "#FFF",
  };
  treeControl = new NestedTreeControl<GroupNode>((node) => node.children);
  originalSidebarDataSource: any[] = [];
  sidebarDataSource = new MatTreeNestedDataSource<GroupNode>();
  roles: any[];
  downloadingCsv: boolean = false;
  deactivatedTab: boolean = false;
  search: string = "";
  searchByCompanyInput: string = "";
  allChecked: boolean = false;
  allRoleFilterChecked: boolean = true;
  allLicenseFilterChecked: boolean = true;
  allExtensionFilterChecked: boolean = true;
  statuses: any = [{ label: "Active" }, { label: "Invited" }];
  licenseTypes: any[] = LICENSE_TYPES;
  subscription_types: any[] = SUBSCRIPTION_TYPES;
  account_status_list: any[] = ACCOUNT_STATUS;
  extensions: any[] = [
    { label: "Yes", checked: true },
    { label: "No", checked: true },
  ];
  loading: boolean;
  pageSize: number = 100;
  usersCount: number = 0;
  totalPages: number = 0;
  currentPage: number = 1;
  loadingUsersCategories: boolean;
  individualUsersCategories: any[] = [];
  companyUsersCategories: any[] = [];

  checklistSelection = new SelectionModel<GroupNode>(true /* multiple */);
  constructor(
    private readonly apiService: SuperAdminApiService,
    private readonly usersApiService: UsersApiService,
    private readonly adminApiService: AdminApiService,
    public authService: AuthService,
    private readonly notification: NotificationService,
    private readonly clipboardService: ClipboardService,
    public dialog: DialogService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly nav: NavigationService,
    private readonly config: NgbPopoverConfig,
    private readonly dataService: DataService
  ) {
    config.triggers = "hover";
    config.container = "body";

    const roleToolTips = {
      "Vengreso Admin":
        "Grants full access to all system functionalities and manages all user groups and companies. (This is the most powerful user role.)",
      "Global Admin":
        "Manages all user groups and has system-wide permissions at a company level.",
      "Group Admin":
        "Administers a specific user group and/or subgroup, managing its members and permissions within that group and/or subgroup.",
      "Reporting Admin":
        "Has access to generate and view reports across the system but cannot modify user data or functionalities.",
      User: "Basic user with no access to admin functionalities.",
    };
    this.roles = ROLES.map((role) => ({
      name: role,
      checked: true,
      tooltip: roleToolTips[role],
    }));
  }

  ngOnInit(): void {
    // this.router.navigateByUrl(url, {
    //   state: { companySlug },
    // });
    // recover the state in the component to check for the companySlug
    const companySlug = this.route.snapshot.paramMap.get('companySlug') ?? this.router.getCurrentNavigation()?.extras?.state?.companySlug;
    
    if (companySlug) {
      const companyNode = this.sidebarDataSource.data.find((node) => node.id === companySlug);

      this.toggleSelection(companyNode);
    }

    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        switch (params.get("path")) {
          case "add-manually":
            this.addUserManually(true);
            break;
          case "add-by-email":
            this.addUserByEmail(true);
            break;
          case "deactivated":
            this.deactivatedTab = true;
            break;
          default:
            break;
        }
      })
    );

    this.loading = true;
    this.loadAllUsers();
    this.loadUsersCategories();

    this.dataService.downloadComplete$.subscribe((filename) => {
      if (filename == "Users.csv")
        this.notification.toastWithConfig(
          "The CSV has compiled and downloaded to your computer",
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          }
        );
    });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }

    this.disableHeaderSorting();
  }

  onPageChange(event: PageEvent) {
    const isDifferentPage = event.pageIndex + 1 !== this.currentPage;
    const isDifferentPageSize = event.pageSize !== this.pageSize;

    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;

    if (isDifferentPage || isDifferentPageSize) {
      this.loadAllUsers();
    }
  }

  searchCompanies() {
    const filterValue = this.searchByCompanyInput.trim().toLowerCase();
    this.sidebarDataSource.data = this.originalSidebarDataSource
      .map((category) => ({
        ...category,
        children: category.children?.filter((item) =>
          item.name.toLowerCase().includes(filterValue)
        ),
      }))
      .filter((category) => category.children?.length > 0);

    this.sidebarDataSource.data.forEach((category) => {
      if (category.children?.length > 0) {
        this.treeControl.expandDescendants(category);
      }
    });
  }

  loadUsersCategories(): void {
    this.loadingUsersCategories = true;
    this.apiService.getUserCategories(this.deactivatedTab).subscribe({
      next: (response: any) => {
        if (response.result.success) {
          const data = response.result.data;

          this.originalSidebarDataSource = data.map((category) => ({
            id: category.id,
            name: category.name,
            count: category.items.length,
            children: category.items.map((item: any) => ({
              id: item.id,
              name: item.name,
              count: item.count,
            })),
          }));

          this.sidebarDataSource.data = this.originalSidebarDataSource;
        }
      },
      error: (error: any) => console.log(error),
      complete: () => (this.loadingUsersCategories = false),
    });
  }

  get getSort() {
    let sortBy: "name" | "created_at" = "created_at";
    let sortOrder: SortDirection = "desc";

    if (this.selectedSort === "a_z" || this.selectedSort === "z_a") {
      sortBy = "name";
    }

    if (this.selectedSort === "a_z" || this.selectedSort === "first_added") {
      sortOrder = "asc";
    }

    return { sortBy, sortOrder };
  }

  loadAllUsers(): void {
    if (this.loadUsersTimeout) {
      clearTimeout(this.loadUsersTimeout);
    }

    const makeRequest = () => {
      if (!this.dataSource) {
        this.dataSource = new MatTableDataSource();
      }

      const allIndividuals = this.checklistSelection.selected.find(
        (item) => item.id === "individuals"
      );

      const categories = this.checklistSelection.selected
        .filter(
          (node) =>
            !node.children &&
            !allIndividuals?.children?.some((child) => child.id === node.id)
        )
        .map((node) => node.id);

      if (allIndividuals) {
        categories.push("all_individuals");
      }

      this.loading = true;
      this.dataSource = new MatTableDataSource([]);
      this.usersApiService
        .getUsers({
          deactivated: this.deactivatedTab,
          page: this.currentPage,
          perPage: this.pageSize,
          categories: categories.join(","),
          filter: this.search,
          sortBy: this.getSort.sortBy,
          sortOrder: this.getSort.sortOrder,
          selectedSubscriptionTypes : this.selectedSubscriptionTypes.join(","), 
          selectedAccountStatus : this.selectedAccountStatus.join(",")          
        })
        .subscribe({
          next: (response: any) => {
            if (response.result.success) {
              const data = response.result.data.items;
              this.usersCount = Number(response.result.data.total);
              this.totalPages = Number(response.result.data.total_pages);
              this.currentPage = Number(response.result.data.current_page);

              this.dataSource = new MatTableDataSource(data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.dataSource.sort.active = this.getSort.sortBy;
              this.dataSource.sort.direction = this.getSort.sortOrder;
              this.dataSource.sort.sortChange.emit();
            }
          },
          error: (error: any) => console.log(error),
          complete: () => (this.loading = false),
        });
    };

    this.loadUsersTimeout = setTimeout(makeRequest, 600);
  }

  addUserByEmail(navigateBack: boolean = false) {
    const dialogRef = this.dialog.openDialogComponent(
      VengresoAddUserByEmailComponent,
      { class: "portal-admin" },
      "600px"
    );

    dialogRef.afterClosed().subscribe((users: any) => {
      if (users) {
        this.dataSource.data = users.concat(this.dataSource?.data);
        this.usersCount = this.dataSource.data.length;
        this.loadAllUsers();
      }
      
      dialogRef.close();
      
      if (navigateBack) {
        this.router.navigate(["/vengreso-admin/users"]);
      }
    });
  }

  addUserManually(navigateBack: boolean = false) {
    const dialogRef = this.dialog.openDialogComponent(
      VengresoAddUserManuallyComponent,
      { class: "portal-admin" },
      "600px"
    );

    dialogRef.afterClosed().subscribe((user: any) => {
      if (user) {
        this.dataSource.data = [user].concat(this.dataSource?.data);
        this.usersCount = this.dataSource.data.length;
      }
      dialogRef.close();

      if (navigateBack) {
        this.router.navigate(["/vengreso-admin/users"]);
      }
    });
  }

  get showActionButton(): boolean {
    return (
      this.dataSource?.connect().value.filter((t: any) => t.checked).length > 0
    );
  }

  get isVengresoAdmin(): boolean {
    return this.authService.getUserRole().includes("Vengreso Admin");
  }

  updateAllChecked() {
    this.allChecked = this.dataSource
      ?.connect()
      .value?.every((t: any) => t.checked);
  }

  someChecked(): boolean {
    if (this.dataSource?.connect().value == null) {
      return false;
    }
    return (
      this.dataSource?.connect().value.filter((t: any) => t.checked).length >
        0 && !this.allChecked
    );
  }

  checkAll(checked: boolean) {
    this.allChecked = checked;
    if (this.dataSource?.connect().value == null) {
      return;
    }
    this.dataSource?.connect().value.forEach((t: any) => (t.checked = checked));
  }

  someRoleFilterChecked() {
    return (
      this.roles.filter((t: any) => t.checked).length > 0 &&
      !this.allRoleFilterChecked
    );
  }

  assignedRoleDisabled(dropdownRole: string, user: any): boolean {
    // If there are no groups in the company user assigned to, Group Admin role option should be disabled.
    if (dropdownRole == "Group Admin" && !user.company_has_groups) {
      return true;
    }

    // If the company of user has no groups, disable 'Vengreso Admin' and 'Group Admin'
    // if(['Group Admin', 'Vengreso Admin'].includes(dropdownRole) && !user.company_has_groups){
    //   return true;
    // }

    return (
      (dropdownRole === user.role &&
        !["Reporting Admin", "Group Admin"].includes(dropdownRole)) ||
      (dropdownRole == "Vengreso Admin" && user.company_slug !== "vengreso")
    );
  }

  someLicenseFilterChecked(): boolean {
    return (
      this.licenseTypes.filter((t: any) => t.checked).length > 0 &&
      !this.allLicenseFilterChecked
    );
  }

  get enableExportCsv(): boolean {
    return this.dataSource?.data.filter((t: any) => t.checked).length > 0;
  }

  exportCsv() {
    this.downloadingCsv = true;

    const selectedUsers = this.dataSource?.data
      .filter((t: any) => t.checked)
      .map((user: any) => user.id);

    this.apiService.exportUsersCSV(
      this.deactivatedTab,
      selectedUsers.join(",")
    );

    this.downloadingCsv = false;
  }

  disableHeaderSorting() {
    const matSortHeaderElements = document.querySelectorAll(
      ".mat-sort-header-container"
    );
    matSortHeaderElements.forEach((element) => {
      (element as HTMLElement).style.cursor = "default";
      element.addEventListener("click", (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    });
  }

  sortBy(sortKey: string, direction: SortDirection = "asc") {
    if (sortKey === "name" && direction === "asc") {
      this.selectedSort = "a_z";
    } else if (sortKey === "name" && direction === "desc") {
      this.selectedSort = "z_a";
    } else if (sortKey === "created_at" && direction === "asc") {
      this.selectedSort = "first_added";
    } else if (sortKey === "created_at" && direction === "desc") {
      this.selectedSort = "last_added";
    }

    this.loadAllUsers();
  }

  // The method triggered by the checkbox change
  filterByOptions() {
    // Filter the selected subscription types
    this.selectedSubscriptionTypes = this.subscription_types
      .filter(subscription => subscription.checked)
      .map(subscription => subscription.id); // Get selected plan labels

    this.selectedAccountStatus = this.account_status_list
      .filter(status => status.checked)
      .map(status => status.id); // Get selected plan labels
    
    // Now call loadAllUsers to refresh the users based on the selected filters
    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['selectd_types']);
    };

    this.dataSource.filter = this.selectedSubscriptionTypes;
    this.dataSource.filter = this.selectedAccountStatus;

    this.loadAllUsers();
  }

  customFilterApplied(data: any[]): boolean {
    return data.some((g: any) => g.checked === false);
  }

  get filtersClear() {
    return (
      this.search.trim().length < 1 &&
      this.statuses.every((t: any) => t.checked === true) &&
      this.licenseTypes.every((t: any) => t.checked === true) &&
      this.roles.every((t: any) => t.checked === true)
    );
  }

  clearFilters() {
    this.search = "";
    this.loadAllUsers();
    this.statuses.forEach((t: any) => (t.checked = true));
    this.licenseTypes.forEach((t: any) => (t.checked = true));
    this.roles.forEach((t: any) => (t.checked = true));
    this.allRoleFilterChecked = true;
    this.allLicenseFilterChecked = true;
  }

  resetFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data)
        .reduce((currentTerm, key) => {
          return currentTerm + (data as { [key: string]: any })[key] + " ";
        }, "")
        .toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
  }

  hasChild = (_: number, node: GroupNode) =>
    !!node.children && node.children.length > 0;

  toggleSelection(node: GroupNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    if (node.children?.length > 0) {
      this.checkAllChildrenSelection(node);
    }
    this.loadAllUsers();
  }

  /** Checks all the parents when a leaf node is selected/unselected */
  checkAllChildrenSelection(node: GroupNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);

    node.children?.forEach((child) => {
      if (nodeSelected) {
        this.checklistSelection.select(child);
      } else {
        this.checklistSelection.deselect(child);
      }
    });
  }

  /** Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: GroupNode): void {
    let parent: GroupNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  descendantsPartiallySelected(node: GroupNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.checklistSelection.isSelected(node);
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: GroupNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /** Get the parent node of a node */
  getParentNode(node: GroupNode): GroupNode | null {
    let parent: GroupNode | null = null;

    this.sidebarDataSource.data.forEach((n) => {
      if (n.children?.includes(node)) {
        parent = n;
      }
      if (n.children) {
        n.children.forEach((child) => {
          if (child.children?.includes(node)) {
            parent = child;
          }
        });
      }
    });

    return parent;
  }

  resendInviteEmail(data) {
    const isBulkOperation = Array.isArray(data) && data.length > 0;

    if (isBulkOperation) {
      const dialogRef = this.dialog.openDialogComponent(
        EditUserComponent,
        { data, action: "resendInvite", class: "portal-admin" },
        Array.isArray(data) ? "600px" : "500px"
      );

      dialogRef.afterClosed().subscribe((data: any) => {
        if (data) {
          this.dataSource.data = this.dataSource?.data.map((user: any) => ({
            ...user,
            checked: false,
          }));
        }
      });
    } else {
      let request: Observable<any>;

      if (
        this.isVengresoAdmin &&
        !this.authService.onCompanyMasqueradeSession
      ) {
        request = this.apiService.resendUserInvitation({
          emails: [data.email],
        });
      } else {
        request = this.adminApiService.resendUserInvitation({
          email: data.email,
        });
      }

      request.subscribe({
        next: () => {
          this.notification.toastWithConfig(
            "Invite email resent successfully",
            null,
            {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["green-notification"],
            }
          );
        },
        error: (error) => console.log(error),
      });
    }
  }

  copyInviteLink(user) {
    this.clipboardService.copyFromContent(user.invitation_link);
    this.notification.toastWithConfig("Invite link copied successfully", null, {
      verticalPosition: "top",
      horizontalPosition: "center",
      panelClass: ["green-notification"],
    });
  }

  assignRole(user: any, role): void {
    if (!user.company || user.company == "Not Assigned") {
      return;
    }

    let downgradeRole =
      user.role &&
      user.role !== "User" &&
      (role.name === "User" ||
        (["Group Admin", "Reporting Admin"].includes(role.name) &&
          user.role === "Global Admin") ||
        (["Global Admin", "Group Admin", "Reporting Admin"].includes(
          role.name
        ) &&
          user.role === "Vengreso Admin"));
    let confirmAssignRole = false;
    let modalWidth = "620px";

    if (["Global Admin", "User"].includes(role.name)) {
      confirmAssignRole = true;
      modalWidth = "500px";
    }

    let dialogRef = this.dialog.openDialogComponent(
      AssignRoleComponent,
      {
        data: user,
        role,
        confirmAssignRole,
        downgradeRole,
        class: "portal-admin",
      },
      modalWidth
    );

    dialogRef.afterClosed().subscribe((updatedUser: any) => {
      if (updatedUser)
        this.dataSource.data = this.dataSource?.data.map((user: any) => {
          if (Array.isArray(updatedUser)) {
            const newUser = updatedUser.find((u) => u.id === user.id);
            return newUser ? { ...user, ...newUser } : user;
          }

          return user.id === updatedUser.id
            ? { ...user, ...updatedUser }
            : user;
        });
    });
  }

  editProfile(user: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data: user, action: "editProfile", class: "portal-admin" },
      "600px"
    );

    dialogRef.afterClosed().subscribe((updatedUser: any) => {
      if (updatedUser)
        this.dataSource.data = this.dataSource?.data.map((user: any) =>
          user.id === updatedUser.id ? { ...user, ...updatedUser } : user
        );
    });
  }

  resetPassword(data: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "resetPassword", class: "portal-admin" },
      Array.isArray(data) ? "600px" : "500px"
    );

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.dataSource.data = this.dataSource?.data.map((user: any) => ({
          ...user,
          checked: false,
        }));
      }
    });
  }

  assignMoveTo(user: any) {
    const dialogRef = this.dialog.openDialogComponent(
      AssignMoveToCompanyComponent,
      { user, class: "portal-admin" },
      "600px"
    );

    dialogRef.afterClosed().subscribe((movedUsers: any[]) => {
      if (movedUsers) {
        const movedUsersMap = Object.fromEntries(
          movedUsers.map((obj) => [obj.id, obj])
        );

        this.dataSource.data = this.dataSource?.data.map((user: any) =>
          movedUsersMap[user.id]
            ? { ...user, ...movedUsersMap[user.id], checked: false }
            : { ...user, checked: false }
        );
      }
    });
  }

  deleteUser(data: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "deleteUser", class: "portal-admin" },
      "none"
    );

    dialogRef.afterClosed().subscribe((deactivatedUsersIds: any[]) => {
      if (
        Array.isArray(deactivatedUsersIds) &&
        deactivatedUsersIds.length > 0
      ) {
        this.dataSource.data = this.dataSource?.data.filter(
          (user: any) => !deactivatedUsersIds.includes(user.id)
        );
        this.usersCount = this.dataSource.data.length;
      }
    });
  }

  deactivateUser(data: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "deactivateUser", class: "portal-admin" },
      "none"
    );

    dialogRef.afterClosed().subscribe((deactivatedUsersIds: any[]) => {
      if (
        Array.isArray(deactivatedUsersIds) &&
        deactivatedUsersIds.length > 0
      ) {
        this.dataSource.data = this.dataSource?.data.filter(
          (user: any) => !deactivatedUsersIds.includes(user.id)
        );
        this.usersCount = this.dataSource.data.length;

        this.router.navigate(["/vengreso-admin/users/deactivated"]);
      }
    });
  }

  reactivateUser(user: any) {
    const data: ConfirmationDialogData = {
      buttonConfirm: "Reactivate",
      title: "Reactivate User",
      message: `Are you sure you want to reactivate <strong>${user.first_name} ${user.last_name}</strong>?`,
    };

    const dialogRef = this.dialog.openDialogComponent(
      ConfirmationModalComponent,
      { data, class: "portal-admin" },
      "unset"
    );

    dialogRef.afterClosed().subscribe((success: boolean) => {
      if (success) {
        this.usersApiService
          .reactivateUsers({ users: [{ id: user.id }] })
          .subscribe((response: any) => {
            if (response?.result?.success) {
              const message = `${user.first_name} ${user.last_name} reactivated successfully`;

              this.notification.toastWithConfig(message, null, {
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["green-notification"]
              });

              this.router.navigate(["/vengreso-admin/users"]);
            } else {
              const message = response?.result?.message ?? "Failed to reactivate user";
              this.notification.toastWithConfig(message, null, {
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["red-notification"]
              });
            }
          });
      }
    });
  }

  loginAs(user) {
    if (user.is_invite) {
      this.notification.toastWithConfig(
        `${user.email} didn't accept its invitation.`,
        null,
        {
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: ["blue-notification"],
        }
      );
      return;
    }

    this.usersApiService.masqueradeUser(user.id).subscribe({
      next: (response) => {
        if (response.result.access_token) {
          const data = response.result;

          this.authService.masqueradeAsUser(data);
        } else {
          this.notification.toastWithConfig(
            `Failed to masquerade this user.`,
            null,
            {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["red-notification"],
            }
          );
        }
      },
      error: (error: any) => console.log(error),
      complete: () => (this.loadingUsersCategories = false),
    });
  }

  bulkResetPassword() {
    this.resetPassword(
      this.dataSource?.connect().value.filter((t: any) => t.checked)
    );
  }

  bulkAssignMoveTo() {
    let users = this.dataSource.data.filter((t: any) => t.checked);

    const dialogRef = this.dialog.openDialogComponent(
      BulkAssignMoveToCompanyComponent,
      {
        data: users,
        class: "portal-admin"
      },
      "400px"
    );

    dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.dataSource.data = this.dataSource.data.map(
            user => {
              const updatedUser = result.find(u => u.id === user.id);
              return updatedUser ? { ...user, ...updatedUser } : user;
            }
          );
        }
      });
  }


  bulkResendInvite() {
    this.resendInviteEmail(
      this.dataSource?.connect().value.filter((t: any) => t.checked)
    );
  }

  bulkDeleteUser() {
    const users = this.dataSource.connect().value.filter((c: any) => c.checked)

    this.deleteUser(users);
  }

  bulkDeactivateUser() {
    this.deactivateUser(
      this.dataSource?.connect().value.filter((t: any) => t.checked)
    );
  }

  bulkReactivateUser() {
    const dialogRef = this.dialog.openDialogComponent(
      ReactivateComponent,
      { data: this.dataSource?.connect().value.filter((t: any) => t.checked), class: "portal-admin" },
      "550px"
    );

    dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.router.navigate(["/vengreso-admin/users"]);
        }
      });
  }

  get showBulkResendInvite(): boolean {
    const selectedUsers = this.dataSource?.connect().value.filter((t: any) => t.checked);
    
    return selectedUsers.some((user: any) => user.status === "Invited");
  }

  pushItToHubspot(user) {
    this.usersApiService.pushItToHubspot(user.id).subscribe({
      next: (response) => {
        this.notification.toastWithConfig(
          `User pushed to Hubspot.`,
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          }
        );
      },
      error: (error: any) => console.log(error),
    });
  }

  recalculateUserInfo(user) {
    this.usersApiService.recalculateUserInfo(user.id).subscribe({
      next: (response) => {
        this.notification.toastWithConfig(
          `User recalculated and pushed to Hubspot.`,
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          }
        );
      },
      error: (error: any) => console.log(error),
    });
  }

  get showDevTools(): boolean {
    return this.authService.userDetails.user_details.email === 'jonatas.souza@vengreso.com'
  }
}
