import { AdminLayoutComponent, AuthLayoutComponent } from "./core";

import { Routes } from "@angular/router";
import { TemplatesComponent } from "./vengreso/templates/templates.component";
import { TempSharedShortcutComponent } from "./vengreso/shortcuts/temp-shared-shortcut/temp-shared-shortcut";
import { ShortcutsComponent } from "./vengreso/shortcuts/shortcuts.component";
import { ProfileComponent } from "./vengreso/profile/profile.component";
import { TemplatesResolver } from "./vengreso/templates/templates.resolver";
import { ShortcutCategoryListResolver } from "./vengreso/shortcuts/shortcut-category-list.resolver";
import { ShortcutListResolver } from "./vengreso/shortcuts/shortcut-list.resolver";
import { SettingsResolver } from "./vengreso/settings/settings.resolver";
import { AuthGuardService } from "./core/core-services/services/auth-guard.service";
//import { AddShortcutCategoryComponent } from "./vengreso/shortcuts/add-shortcut-category/add-shortcut-category.component";
import { ProfileResolver } from "./vengreso/profile/profile.resolver";
import { AdvancedSearchResultComponent } from "./vengreso/advanced-search-result/advanced-search-result.component";
import { SettingsComponent } from "./vengreso/settings/settings.component";
import { NewPlanComponent } from "./vengreso/settings/new-plan/new-plan.component";
import { PaymentDetailsComponent } from "./vengreso/settings/payment-details/payment-details.component";
import { PlansDetailsComponent } from "./vengreso/settings/plans/plans-details.component";
import { FlyLearningComponent } from "./vengreso/flylearning/fly-learning.component";
import { PaymentMethodDetailsComponent } from "./vengreso/settings/payment-method-details/payment-method-details.component";
import { AddPaymentMethodComponent } from "./vengreso/settings/payment-method-details/add-payment-method/add-payment-method.component";
import { BillingHistoryComponent } from "./vengreso/settings/billing-history/billing-history.component";
import { FlyCutInviteComponent } from "./vengreso/share/fly-cut-invite/fly-cut-invite.component";
import { FlyShareInviteGuardService } from "./core/core-services/services/flyshare-invite-guard.service";
import { FlyshareComponent } from "./vengreso/share/flyshare/flyshare.component";
import { AdminUsersComponent } from "./admin/admin-users/admin-users.component";
import { AdminCompaniesComponent } from "./admin/admin-companies/admin-companies.component";
import { AdminGuardService } from "./core/core-services/services/admin-guard.service";
import { GlobalAdminUsersComponent } from "./admin/global-admin-users/global-admin-users.component";
import { GroupComponent } from "./admin/group/group.component";
import { ReportingComponent } from "./admin/reporting/reporting.component";
import { GroupsComponent } from "./admin/groups/groups.component";
import { CompanyComponent } from "./admin/admin-companies/company/company.component";
import { AdminGroupComponent } from "./admin/admin-companies/admin-group/admin-group.component";
import { VengresoReportingComponent } from "./admin/vengreso/reporting/reporting.component";
import { SuperAdminGuardService } from "./core/core-services/services/super-admin-guard.service";
import { AccountCenterComponent } from "./admin/account-center/account-center.component";
import { CMCComponent } from "./admin/cmc/cmc.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";

export const AppRoutesMaintenance: Routes = [
  { path: '**', component: MaintenanceComponent },
];

export const AppRoutes: Routes = [
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        resolve: {
          settings: SettingsResolver,
        },
        canActivate: [AuthGuardService],
      },
      {
        path: "templates/:categoryId",
        component: TemplatesComponent,
        resolve: {
          templateList: TemplatesResolver,
        },
        canActivate: [AuthGuardService],
      },
      {
        path: "flyplates/:categoryName",
        component: TemplatesComponent,
        resolve: {
          templateList: TemplatesResolver,
        },
        canActivate: [AuthGuardService],
      },
      {
        path: "flyplates/:categoryName/:subCategoryName",
        component: TemplatesComponent,
        resolve: {
          templateList: TemplatesResolver,
        },
        canActivate: [AuthGuardService],
      },
      {
        path: "flyplates/:categoryName/:subCategoryName/:subSubCategoryName",
        component: TemplatesComponent,
        resolve: {
          templateList: TemplatesResolver,
        },
        canActivate: [AuthGuardService],
      },
      {
        path: "advanced-search-result",
        component: AdvancedSearchResultComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "shortcuts/:categoryId",
        component: ShortcutsComponent,
        resolve: {
          shortcutList: ShortcutListResolver,
        },
        canActivate: [AuthGuardService],
      },
      {
        path: "shortcuts/:categoryId/:lv1CategoryId",
        component: ShortcutsComponent,
        resolve: {
          shortcutList: ShortcutListResolver,
        },
        canActivate: [AuthGuardService],
      },
      {
        path: "shortcuts/:categoryId/:lv1CategoryId/:lv2CategoryId",
        component: ShortcutsComponent,
        resolve: {
          shortcutList: ShortcutListResolver,
        },
        canActivate: [AuthGuardService],
      },
      {
        path: "settings",
        component: SettingsComponent,
        resolve: {
          settings: SettingsResolver,
          shortcutCategoryList: ShortcutCategoryListResolver,
        },
        canActivate: [AuthGuardService],
      },
      {
        path: "plans",//this is deprecated just keep it in case any one using this url
        component: PlansDetailsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "flylearning",
        component: FlyLearningComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "pricing",
        component: PlansDetailsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "new-plan/:id",
        component: NewPlanComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "payment-details/:id",
        component: PaymentDetailsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "payment-method-details",
        component: PaymentMethodDetailsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "add-payment-method",
        component: AddPaymentMethodComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "billing-history",
        component: BillingHistoryComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "profile",
        component: ProfileComponent,
        resolve: {
          profile: ProfileResolver,
        },
        canActivate: [AuthGuardService],
      },
      // {
      //   path: "add-shortcut-category",
      //   component: AddShortcutCategoryComponent,
      //   resolve: {
      //     shortcutCategoryList: ShortcutCategoryListResolver,
      //   },
      //   canActivate: [AuthGuardService],
      // },
      {
        path: "share/:flyshareId",
        component: FlyCutInviteComponent,
        canActivate: [FlyShareInviteGuardService]
      },
      {
        path: "flyshare/:id",
        component: FlyshareComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "session",
        loadChildren: () =>
          import("./session/session.module").then((m) => m.SessionModule),
      },
      {
        path: "shared-flycut/:key",
        component: TempSharedShortcutComponent,
      },
      {
        path: 'onboardingprogress_training',
        redirectTo: "/flyboard?showonboardingprogresstraining=true"
      },
      {
        matcher: (url) => {
          // return url.length >= 1 && url[0].path.match('^onboarding.*$')
          return url.length >= 1 && url[0].path.match('onboarding') && url[0].path.match('^((?!onboardingprogress_training).)*$')
            ? { consumed: url }
            : null;
        },
        loadChildren: () =>
          import("./onboarding/onboarding.module").then((m) => m.OnboardingModule),
      },
    ],
  },
  {
    path: "",
    component: CMCComponent,
    canActivate: [SuperAdminGuardService],
    children: [
      {
        path: "vengreso-admin/users",
        component: AdminUsersComponent,
        canActivate: [SuperAdminGuardService],
      },
      {
        path: "vengreso-admin/users/:path",
        component: AdminUsersComponent,
        canActivate: [SuperAdminGuardService],
      },
      {
        path: "vengreso-admin/companies",
        component: AdminCompaniesComponent,
        canActivate: [SuperAdminGuardService],
      },
      {
        path: "vengreso-admin/companies/:path",
        component: AdminCompaniesComponent,
        canActivate: [SuperAdminGuardService],
      },
      {
        path: "vengreso-admin/companies/:companySlug/:path",
        component: CompanyComponent,
        canActivate: [SuperAdminGuardService],
      },
      {
        path: "vengreso-admin/companies/:companySlug/:groupSlug/:subGroupSlug",
        component: AdminGroupComponent,
        canActivate: [SuperAdminGuardService],
      },
      {
        path: "vengreso-admin/reporting/:path",
        component: VengresoReportingComponent,
        canActivate: [SuperAdminGuardService],
      },
    ]
  },
  {
    path: "",
    component: AccountCenterComponent,
    canActivate: [AdminGuardService],
    children: [
      {
        path: ":companySlug/admin/users",
        component: GlobalAdminUsersComponent,
        canActivate: [AdminGuardService],
      },
      {
        path: ":companySlug/admin/users/:path",
        component: GlobalAdminUsersComponent,
        canActivate: [AdminGuardService],
      },
      {
        path: ":companySlug/admin/groups",
        component: GroupsComponent,
        canActivate: [AdminGuardService],
      },
      {
        path: ":companySlug/admin/groups/:groupSlug/:subGroupSlug",
        component: GroupComponent,
        canActivate: [AdminGuardService],
      },
      {
        path: ":companySlug/admin/groups/:groupSlug/:subGroupSlug/add-users",
        component: GroupComponent,
        canActivate: [AdminGuardService],
      },
      {
        path: ":companySlug/admin/groups/:groupSlug/:subGroupSlug/remove-users",
        component: GroupComponent,
        canActivate: [AdminGuardService],
      },
      {
        path: ":companySlug/admin/reporting/:path",
        component: ReportingComponent,
        canActivate: [AdminGuardService],
      },
    ]
  },
  {
    path: ":companySlug/admin/reporting",
    redirectTo: ":companySlug/admin/reporting/overview",
    pathMatch: "full"
  },
  {
    path: "vengreso-admin/reporting",
    redirectTo: "vengreso-admin/reporting/overview",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "session/404",
  },
];
