import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { AdminApiService } from '../services/admin-api.service';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {
  company_slug: string;
  action: string;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private adminApiService: AdminApiService,
    private notification: NotificationService,
  ) { 
    this.action = this.data.action;
    this.company_slug = this.data.company_slug;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      group: [null, [Validators.required]],
    });
  }

  add(): void {
    let request: Observable<Response>;
    
    if(this.action == "add-group") {
      request = this.adminApiService.createGroup({name: this.form.value.group}, this.company_slug);
    } else if (this.action == "add-subgroup") {
      request = this.adminApiService.createSubgroup(this.data.groupId, {name: this.form.value.group, users: []}, true, this.company_slug);
    }

    request.subscribe({
      next: (response:any) => {
        this.close(response.result.group);
        this.notification.toastWithConfig(
          `New ${
            this.action == "add-group"
              ? "group"
              : "subgroup"
          } added`,
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          }
        );
      },
      error: (error) => {
        console.log('error', error);
        if(error.status == 422){
          this.form.controls['group'].setErrors({ not_unique: true });
        }
      }
    });
  }

  close(data?:any): void {
    this.dialogRef.close(data);
  }
}
