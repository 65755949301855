import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  remainingTime: string = '';
  targetTimeUTC: Date;

  constructor() {
    this.targetTimeUTC = new Date(1736277546776);
  }

  ngOnInit(): void {
    this.startCountdown();
  }

  startCountdown() {
    const timer = setInterval(() => {
      const now = new Date();
      const remainingMilliseconds = this.targetTimeUTC.getTime() - now.getTime();

      if (remainingMilliseconds <= 0) {
        clearInterval(timer);
        this.remainingTime = '00:00:00';
        return;
      }

      const remainingSeconds = Math.floor(remainingMilliseconds / 1000);
      const hours = Math.floor(remainingSeconds / 3600);
      const minutes = Math.floor((remainingSeconds % 3600) / 60);
      const secs = remainingSeconds % 60;

      this.remainingTime = `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(secs)}`;
    }, 1000);
  }

  padNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
}
