import { Injectable } from "@angular/core";
import { forEach } from "lodash";
import { LocalStorageService, SessionStorageService, SyncStorage, LocalStorage, SessionStorage } from "ngx-webstorage";
import { BehaviorSubject } from "rxjs";

// Declared as const as this should not be reassigned from anywhere in app
const sharedData = {};

@Injectable()
export class SharedDataService {

  public showExtensionPrompt = new BehaviorSubject<boolean>(false)
  public showExtensionPrompt$ = this.showExtensionPrompt.asObservable()

  public showDownloadModalTimer = new BehaviorSubject<boolean>(false)
  public showdownloadModalTimer$ = this.showDownloadModalTimer.asObservable()
  
  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly sessionStorage: SessionStorageService
  ) {}

  get() {
    return sharedData;
  }

  get Storage() {
    return this.isMasquerading ? SessionStorage : LocalStorage;
  }

  get storage(): SyncStorage {
    return this.isMasquerading ? this.sessionStorage : this.localStorage;
  }

  get isMasquerading() {
    const masqueradeUserId = sessionStorage.getItem(
      "fms-cmc-masquerading-user-id"
    );
  
    return !!masqueradeUserId;
  }
  
  clear() {
    const welcomeDismiss = this.getAttribute("welcomeDismiss");
    let FlyMSGـFrontEndـVersion = this.getAttribute("FlyMSGـFrontEndـVersion");
    // Clearing sharedData one by one as a constant cannot be reassigned
    forEach(sharedData, (val, key) => {
      delete sharedData[key];
    });
    // Clear localStorage
    if (this.isMasquerading) {
      this.sessionStorage.clear();
    } else {
      this.localStorage.clear();
    }
    
    this.setAttribute("FlyMSGـFrontEndـVersion", FlyMSGـFrontEndـVersion);
    this.setAttribute("welcomeDismiss", welcomeDismiss);
  }

  getAttribute(key: string) {
    return sharedData[key];
  }

  setAttribute(key: string, value: any) {
    sharedData[key] = value;
    if (this.isMasquerading) {
      this.sessionStorage.store(key, value);
    } else {
      this.localStorage.store(key, value);
    }
  }

  deleteAttribute(key: string, forceLocalStorage = false) {
    delete sharedData[key];
    if (!this.isMasquerading || forceLocalStorage) {
      this.localStorage.clear(key);
    } else {
      this.sessionStorage.clear(key);
    }
  }

  restoreSharedData(): void {
    const storage = this.isMasquerading ? sessionStorage : localStorage;
    
    const len = storage.length;
    
    for (let i = 0; i < len; i++) {
      try {
        const rawKey = storage.key(i);
        if (!rawKey.startsWith("vengreso.")) continue;
  
        const key = rawKey.replace("vengreso.", "");
  
        const rawValue = storage.getItem(rawKey);
        if (rawValue && rawValue !== "undefined" && rawValue !== "null") {
          let value: any;
          if (this.isMasquerading) {
            value = this.sessionStorage.retrieve(key);
          } else {
            value = this.localStorage.retrieve(key);
          }
    
          if (value === undefined || value === null) {
            console.warn(`Chave "${key}" possui valor inválido (${value}) e será ignorada.`);
            continue;
          }
    
          sharedData[key] = value;
        }
      } catch (error) {
        console.error(`Erro ao processar chave no armazenamento "${storage.key(i)}":`, error);
      }
    }
  }  
}
