import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Country, ICountry } from "country-state-city";
import { findIndex } from "lodash";
import { MatDatepicker } from "@angular/material/datepicker";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import { SuperAdminApiService } from "../../services/super-admin-api.service";
import moment from "moment";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { NavigationService } from "src/app/core/core-services/services/navigation.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ROLES } from "src/app/core/core-services/constants/roles.constant";
import { CompaniesApiService } from "../../services/company/companies-api.service";
import {
  ConfirmationDialogData,
  ConfirmationModalComponent,
} from "src/app/components/confirmation-modal/confirmation-modal.component";
import { ClipboardService } from "ngx-clipboard";
import { EditUserComponent } from "../../edit-user/edit-user.component";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { AdminApiService } from "../../services/admin-api.service";

const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CompanyComponent implements OnInit {
  companyName: string;
  companySlug: string;
  path: string;
  tabs: any[] = [];
  subscriptions = new Subscription();
  editCompanyForm: FormGroup;
  countries: ICountry[] = Country.getAllCountries();
  startDatePicker: MatDatepicker<any>;
  extendContractEndDateOptions: any[] = [
    { label: "None", value: 0 },
    { label: "1 day", value: 1 },
    { label: "3 days", value: 3 },
    { label: "5 days", value: 5 },
    { label: "7 days", value: 7 },
  ];
  termsOfContract: any[] = [
    { label: "6 months", interval_in_months: 6 },
    { label: "1 year", interval_in_months: 12 },
    { label: "2 years", interval_in_months: 24 },
    { label: "Custom", interval_in_months: 0 },
  ];
  licensePlans: any[] = [
    { type: "starter", label: "Starter", min: 0 },
    { type: "growth", label: "Growth", min: 0 },
    { type: "sales_pro", label: "Sales Pro", min: 0 },
    { type: "sales_pro_teams_smb", label: "Sales Pro Teams", min: 1 },
  ];
  businessProOptions: any[] = [
    { value: "yes_dedicated", label: "Yes, dedicated training and coaching" },
    { value: "yes_community", label: "Yes, community training and coaching" },
    { value: "no", label: "No" },
  ];
  loading: boolean = false;
  initialFormData: any;
  displayedColumns: string[] = [];
  selectedSort: string = "last_added";
  groups: any = [{ label: "Not Assigned", checked: true }];
  subgroups: any[] = [];
  licenseTypes: any[] = [];
  allGroupsFilterChecked: boolean = true;
  allSubgroupsFilterChecked: boolean = true;
  statuses: any = [
    { label: "Active", checked: true },
    { label: "Invited", checked: true },
  ];
  expandedElement: any | null;
  dataSource: any;
  usersPerPage: number = 10;
  allRoleFilterChecked: boolean = true;
  roles: any;
  companyPOCs: any[];
  roleColors: {
    [role: string]: string;
  } = {
    "Vengreso Admin": "#d3aae5",
    "Global Admin": "#FEEFEA",
    "Group Admin": "#FEF9EA",
    "Reporting Admin": "#EEEEEE",
    User: "#FFF",
  };
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly apiService: SuperAdminApiService,
    private readonly adminApiService: AdminApiService,
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly cd: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly notification: NotificationService,
    private readonly clipboardService: ClipboardService,
    private readonly nav: NavigationService,
    public dialog: DialogService,
    private readonly companiesApiService: CompaniesApiService
  ) {
    this.roles = ROLES.map((role) => ({ name: role, checked: true }));
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        this.companySlug = params.get("companySlug");

        this.tabs = [
          {
            url: `/vengreso-admin/companies/${this.companySlug}/company-info`,
            label: "Company Info",
          },
          // { url: `/vengreso-admin/companies/${this.companySlug}/poc`, label: "POC" },
          {
            url: `/vengreso-admin/companies/${this.companySlug}/users`,
            label: "Users",
          },
          {
            url: `/vengreso-admin/companies/${this.companySlug}/groups`,
            label: "Groups",
          },
          {
            url: `/vengreso-admin/companies/${this.companySlug}/admins`,
            label: "Admins",
          },
        ];

        this.path = params.get("path");

        switch (this.path) {
          case "users":
            this.loadUsersTab();
            break;
          case "groups":
            this.loadGroupsTab();
            break;
          case "admins":
            this.loadUsersTab(true);
            break;
          case "poc":
            this.loadPOCsTab();
            break;
          case "company-info":
            this.loadCompanyTab(true);
            break;
          case "edit":
          default:
            this.loadCompanyTab(false);
            break;
        }
      })
    );
  }
  ngAfterViewInit() {
    this.disableHeaderSorting();
  }

  loadCompanyTab(disabled: boolean): void {
    this.loading = true;
    let USAIndex = findIndex(this.countries, { name: "United States" });
    [this.countries[0], this.countries[USAIndex]] = [
      this.countries[USAIndex],
      this.countries[0],
    ];

    this.editCompanyForm = undefined;

    this.editCompanyForm = this.fb.group({
      id: [{ value: "" }],
      company_name: [{ value: "", disabled }, Validators.required],
      company_address_line1: [{ value: "", disabled }, Validators.required],
      company_address_line2: [{ value: "", disabled }],
      city: [{ value: "", disabled }],
      state: [{ value: "", disabled }],
      country: [{ value: "", disabled }, Validators.required],
      zip_code: [{ value: "", disabled }],
      term_of_contract: [{ value: 12, disabled }, Validators.required],
      custom_term_of_contract: [{ value: null, disabled }],
      contract_start_date: [{ value: "", disabled }, Validators.required],
      contract_end_date: [{ value: "", disabled }],
      business_pro_enterprise_plus: [
        { value: [], disabled },
        [Validators.required, this.validateBusinessProSelection],
      ],
      extend_contract_end_date: [{ value: 0, disabled }],
      total_licenses: [
        { value: 0, disabled },
        [Validators.required, Validators.min(0)],
      ],
      starter: [{ value: 0, disabled }, Validators.min(0)],
      growth: [{ value: 0, disabled }, Validators.min(0)],
      sales_pro: [{ value: 0, disabled }, Validators.min(0)],
      sales_pro_teams_smb: [{ value: 0, disabled }, Validators.min(0)],
      auto_renewal: [{ value: false, disabled }],
    });

    this.onChanges();

    this.companiesApiService.getCompanyBySlug(this.companySlug).subscribe({
      next: (response: any) => {
        if (response.result.success) {
          const data = response.result.data;

          this.companyName = data.company_name;
          this.editCompanyForm.patchValue(data);

          const dateParts = data.contract_start_date.split("/");
          const date = new Date(
            +dateParts[2] + 2000,
            dateParts[0] - 1,
            +dateParts[1]
          );
          this.editCompanyForm.get("contract_start_date").setValue(date);

          const datePartsEnd = data.contract_end_date.split("/");
          const dateEnd = new Date(
            +datePartsEnd[2] + 2000,
            datePartsEnd[0] - 1,
            +datePartsEnd[1]
          );
          this.editCompanyForm
            .get("contract_end_date")
            .setValue(moment(dateEnd).format("MM/DD/YYYY"));
          this.initialFormData = this.editCompanyForm.value;
        }
      },
      error: (err) => {
        console.log(err);
        this.loading = false;
      },
      complete: () => (this.loading = false),
    });
  }

  onChanges(): void {
    this.editCompanyForm
      .get("term_of_contract")
      ?.valueChanges.subscribe((selectedValue) => {
        const customTermOfContractField = this.editCompanyForm.get(
          "custom_term_of_contract"
        );

        if (selectedValue == 0) {
          customTermOfContractField?.setValidators([
            Validators.required,
            Validators.min(0),
          ]);
        } else {
          customTermOfContractField?.clearValidators();
        }

        // Re-evaluate the validation status
        customTermOfContractField?.updateValueAndValidity();
        this.calculateEndDate();
      });

    this.editCompanyForm
      .get("contract_start_date")!
      .valueChanges.subscribe(() => {
        this.calculateEndDate();
      });

    this.editCompanyForm
      .get("custom_term_of_contract")!
      .valueChanges.subscribe(() => {
        this.calculateEndDate();
      });
  }

  private calculateEndDate() {
    const startDate = this.editCompanyForm.get("contract_start_date")?.value;
    const months =
      this.editCompanyForm.get("term_of_contract")?.value !== 0
        ? this.editCompanyForm.get("term_of_contract")?.value
        : this.editCompanyForm.get("custom_term_of_contract")?.value;

    if (startDate && months) {
      const end = moment(startDate).add(Number(months), "months");

      this.editCompanyForm
        .get("contract_end_date")
        ?.setValue(end.format("MM/DD/YY"));
    }
  }

  validateBusinessProSelection(control: any) {
    const selectedOptions = control.value;

    if (!selectedOptions) {
      return null;
    }

    if (
      selectedOptions.includes("no") &&
      (selectedOptions.includes("yes_dedicated") ||
        selectedOptions.includes("yes_community"))
    ) {
      return { invalidSelection: true };
    }

    return null;
  }

  increment(field: string): void {
    const currentValue = this.editCompanyForm.get(field)?.value || 0;
    this.editCompanyForm.get(field)?.setValue(currentValue + 1);
    this.editCompanyForm.updateValueAndValidity();
  }

  decrement(field: string): void {
    const currentValue = this.editCompanyForm.get(field)?.value || 0;
    if (currentValue > 0) {
      this.editCompanyForm.get(field)?.setValue(currentValue - 1);
    }
    this.editCompanyForm.updateValueAndValidity();
  }

  get licenseExceedError(): boolean {
    const totalLicenseInputted = this.licensePlans.reduce(
      (accumulator, currentItem) => {
        return (
          accumulator + this.editCompanyForm.get(currentItem.type)?.value || 0
        );
      },
      0
    );

    const totalLicenses =
      this.editCompanyForm.get("total_licenses")?.value || 0;

    return totalLicenseInputted !== totalLicenses;
  }

  get editFormChanged(): boolean {
    const keys1 = Object.keys(this.initialFormData);

    // Compare the values of each property
    for (let key of keys1) {
      if (this.initialFormData[key] !== this.editCompanyForm.get(key)?.value) {
        return true;
      }
    }

    return false;
  }

  get readOnly(): boolean {
    return this.path == "company-info";
  }

  saveChanges(): void {
    const data: ConfirmationDialogData = {
      buttonConfirm: "Confirm",
      title: "Save changes",
      message: `Are you sure you want to save changes to <strong>${this.companyName}</strong>?`,
    };

    const dialogRef = this.dialog.openDialogComponent(
      ConfirmationModalComponent,
      { data, class: "portal-admin" },
      "unset"
    );

    dialogRef.afterClosed().subscribe((success: boolean) => {
      if (success) {
        if (!this.editCompanyForm.valid) {
          return;
        }

        this.loading = true;
        const company = {
          ...this.editCompanyForm.value,
          contract_start_date: moment(
            this.editCompanyForm.get("contract_start_date")?.value
          ).format("MM/DD/YY"),
        };
        this.companiesApiService.update(company).subscribe({
          next: () => {
            this.notification.toastWithConfig(
              "Company updated successfully",
              null,
              {
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["green-notification"],
              }
            );

            this.nav.navigateTo([`/vengreso-admin/companies`]);
          },
          error: (error: any) => {
            console.log(error);
            this.loading = false;
          },
          complete: () => (this.loading = false),
        });
      }
    });
  }

  loadUsersTab(load_admins_only = false): void {
    this.loading = true;
    this.displayedColumns = ["name", "group", "subgroup", "status", "role"];

    this.dataSource = undefined;
    this.dataSource = new MatTableDataSource();

    this.apiService
      .getCompanyUsers(this.companySlug, load_admins_only)
      .subscribe({
        next: (response: any) => {
          if (response.result.success) {
            const data = response.result.data;
            this.companyName = data.company_name;
            this.companySlug = data.company_slug;
            this.dataSource = new MatTableDataSource(data.users);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.licenseTypes = [
              ...new Map(
                data.users.map((user) => [
                  user.licenseType,
                  { label: user.licenseType, checked: true },
                ])
              ).values(),
            ];
            this.groups = [
              ...new Map(
                data.users.map((user) => [
                  user.group,
                  { label: user.group, checked: true },
                ])
              ).values(),
            ];
            this.subgroups = [
              ...new Map(
                data.users.map((user) => [
                  user.subgroup,
                  { label: user.subgroup, checked: true },
                ])
              ).values(),
            ];
          }
        },
        error: (err) => console.log(err),
        complete: () => (this.loading = false),
      });
  }

  loadPOCsTab(): void {
    this.loading = true;

    this.apiService.getCompanyPOCs(this.companySlug).subscribe({
      next: (response: any) => {
        if (response.result.success) {
          const data = response.result.data;
          this.companyName = data.company_name;
          this.companyPOCs = data.pocs;
        }
      },
      error: (err) => console.log(err),
      complete: () => (this.loading = false),
    });
  }

  someGroupsFilterChecked() {
    return (
      this.groups.filter((t: any) => t.checked).length > 0 &&
      !this.allGroupsFilterChecked
    );
  }

  checkGroupsFilterAll(checked: boolean) {
    this.allGroupsFilterChecked = checked;
    this.groups.forEach((t: any) => (t.checked = checked));

    this.resetFilterPredicate();
  }

  filterByGroup() {
    this.allGroupsFilterChecked = this.groups.every((t: any) => t.checked);
    const filter = this.groups
      .filter((t: any) => t.checked)
      .map((t: any) => t.label);

    if (filter.length < 1) {
      this.resetFilterPredicate();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data["group"]);
    };

    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  someSubgroupsFilterChecked() {
    return (
      this.subgroups.filter((t: any) => t.checked).length > 0 &&
      !this.allSubgroupsFilterChecked
    );
  }

  checkSubgroupsFilterAll(checked: boolean) {
    this.allSubgroupsFilterChecked = checked;
    this.subgroups.forEach((t: any) => (t.checked = checked));

    this.resetFilterPredicate();
  }

  filterBySubgroup() {
    this.allSubgroupsFilterChecked = this.subgroups.every(
      (t: any) => t.checked
    );
    const filter = this.subgroups
      .filter((t: any) => t.checked)
      .map((t: any) => t.label);

    if (filter.length < 1) {
      this.resetFilterPredicate();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data["subgroup"]);
    };

    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterByStatus() {
    const filter = this.statuses
      .filter((t: any) => t.checked)
      .map((t: any) => t.label);

    if (filter.length < 1) {
      this.resetFilterPredicate();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data["status"]);
    };

    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  someRoleFilterChecked() {
    return (
      this.roles.filter((t: any) => t.checked).length > 0 &&
      !this.allRoleFilterChecked
    );
  }

  checkRoleFilterAll(checked: boolean) {
    this.allRoleFilterChecked = checked;
    this.roles.forEach((t: any) => (t.checked = checked));

    this.resetFilterPredicate();
  }

  updateRoleFilterAllChecked() {
    this.allRoleFilterChecked = this.roles.every((t: any) => t.checked);
    const filter = this.roles
      .filter((t: any) => t.checked)
      .map((t: any) => t.name);

    if (this.allRoleFilterChecked || filter.length < 1) {
      this.resetFilterPredicate();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data["role"]);
    };

    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data)
        .reduce((currentTerm, key) => {
          return currentTerm + (data as { [key: string]: any })[key] + " ";
        }, "")
        .toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };

    this.dataSource.filter = "";
  }

  sortBy(sortKey: string, direction: string = "asc") {
    this.dataSource.sort.active = sortKey;
    this.dataSource.sort.direction = direction;
    this.dataSource.sort.sortChange.emit();

    if (sortKey === "name" && direction === "asc") {
      this.selectedSort = "a_z";
    } else if (sortKey === "name" && direction === "desc") {
      this.selectedSort = "z_a";
    } else if (sortKey === "created_at" && direction === "asc") {
      this.selectedSort = "first_added";
    } else if (sortKey === "created_at" && direction === "desc") {
      this.selectedSort = "last_added";
    }
  }

  disableHeaderSorting() {
    const matSortHeaderElements = document.querySelectorAll(
      ".mat-sort-header-container"
    );
    matSortHeaderElements.forEach((element) => {
      (element as HTMLElement).style.cursor = "default";
      element.addEventListener("click", (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    });
  }

  customFilterApplied(data: any[]) {
    return data.some((g: any) => g.checked === false);
  }

  loadGroupsTab(): void {
    this.loading = true;
    this.displayedColumns = ["group", "members", "collapse"];

    this.dataSource = undefined;
    this.dataSource = new MatTableDataSource();

    this.apiService.getCompanyGroups(this.companySlug).subscribe({
      next: (response: any) => {
        if (response.result.success) {
          const data = response.result.data;
          this.companyName = data.company_name;
          this.companySlug = data.company_slug;
          let groups = data.groups;

          groups.forEach((group: any) => {
            let subgroups = group?.subgroups;
            if (Array.isArray(subgroups) && subgroups.length > 0) {
              group.subgroups = new MatTableDataSource(subgroups);
            }
          });

          this.dataSource = new MatTableDataSource(groups);
          this.dataSource.paginator = this.paginator;
        }
      },
      error: (err) => console.log(err),
      complete: () => (this.loading = false),
    });
  }

  toggleRow(element: any, event: Event) {
    event.stopPropagation();

    element?.subgroup !== null
      ? (this.expandedElement =
          this.expandedElement === element ? null : element)
      : null;
    this.cd.detectChanges();
  }

  goToGroup(groupSlug: string, event: Event) {
    if (
      (event.target as HTMLElement).nodeName === "TD" &&
      (event.target as HTMLElement).getAttribute("clickable-cell")
    ) {
      return;
    }

    if (!groupSlug) {
      return;
    }

    this.nav.navigateTo([
      `/vengreso-admin/companies/${this.companySlug}/${groupSlug}/allusers`,
    ]);
  }

  goToSubGroup(groupSlug: string, subGroupSlug: string, event) {
    if (!subGroupSlug) {
      this.goToGroup(groupSlug, event);
    }

    if (!groupSlug) {
      return;
    }

    this.nav.navigateTo([
      `/vengreso-admin/companies/${this.companySlug}/${groupSlug}/allusers/${subGroupSlug}`,
    ]);
  }

  togglePoc(index: number): void {
    this.companyPOCs[index].collapsed = !this.companyPOCs[index].collapsed;
    this.cd.detectChanges();
  }
  
  copyInviteLink(user) {
    this.clipboardService.copyFromContent(user.invitation_link);
    this.notification.toastWithConfig("Invite link copied successfully", null, {
      verticalPosition: "top",
      horizontalPosition: "center",
      panelClass: ["green-notification"],
    });
  }

  resendInviteEmail(data) {
    const isBulkOperation = Array.isArray(data) && data.length > 0;

    if (isBulkOperation) {
      const dialogRef = this.dialog.openDialogComponent(
        EditUserComponent,
        { data, action: "resendInvite", class: "portal-admin" },
        Array.isArray(data) ? "600px" : "500px"
      );

      dialogRef.afterClosed().subscribe((data: any) => {
        if (data) {
          this.dataSource.data = this.dataSource?.data.map((user: any) => ({
            ...user,
            checked: false,
          }));
        }
      });
    } else {
      let request: Observable<any>;

      if (!this.authService.onCompanyMasqueradeSession) {
        request = this.apiService.resendUserInvitation({
          emails: [data.email],
        });
      } else {
        request = this.adminApiService.resendUserInvitation({
          email: data.email,
        });
      }

      request.subscribe({
        next: () => {
          this.notification.toastWithConfig(
            "Invite email resent successfully",
            null,
            {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["green-notification"],
            }
          );
        },
        error: (error) => console.log(error),
      });
    }
  }
}
