import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { MatDialogRef } from "@angular/material/dialog";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";

import { ValidationConstants } from "src/app/core/core-services/constants/validation.constants";
import { Option } from "../models/option.model";
import { AdminApiService } from "../services/admin-api.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { AddGroupComponent } from "../add-group/add-group.component";
import { lastValueFrom } from "rxjs";
import { Router } from "@angular/router";
import { UsersApiService } from "../services/users/users-api.service";
import { CompanyLicense } from "../services/company/companies-dto";
import { CompaniesApiService } from "../services/company/companies-api.service";

interface GroupWithSubgroups extends Option {
  subgroups: Option[];
}

@Component({
  selector: "app-add-user-by-email",
  templateUrl: "./add-user-by-email.component.html",
  styleUrls: ["./add-user-by-email.component.scss"],
})
export class AddUserByEmailComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  groupCtrl = new FormControl("");
  subgroupCtrl = new FormControl("");
  emails: any[] = [];
  assignGroup = false;
  emailValidation: ValidatorFn[] = [
    Validators.pattern(ValidationConstants.EMAIL_EXTRA_REGEX),
    Validators.minLength(5),
    Validators.email,
    Validators.required,
    this.createUniqueEmailValidator(this.emails),
  ];
  emailCtrl: FormControl = new FormControl("", {
    validators: this.emailValidation,
  });
  plans: CompanyLicense[] = [];

  groups: GroupWithSubgroups[] = [];
  subgroups: Option[] = [];

  @ViewChild("emailInput", { static: true })
  emailInput: ElementRef<HTMLInputElement>;

  validatingEmails = false;

  constructor(
    public dialogRef: MatDialogRef<AddUserByEmailComponent>,
    public dialog: DialogService,
    private readonly usersApiService: UsersApiService,
    private readonly companiesApiService: CompaniesApiService,
    private readonly adminApiService: AdminApiService,
    private readonly notification: NotificationService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    let currentUrl = this.router.url;
    if (!currentUrl.includes("add-by-email")) {
      this.location.replaceState(currentUrl + "/add-by-email");
    }

    this.adminApiService.getGroups(false, false).subscribe((response: any) => {
      if (response?.result?.success) {
        const newGroups: GroupWithSubgroups[] = response.result.groups.map(
          (group: any) => ({
            value: group.group.id,
            displayValue: group.group.name,
            subgroups: group.group.subgroups?.map((subgroup: any) => ({
              value: subgroup.group.id,
              displayValue: subgroup.group.name,
            })),
          })
        );
        this.groups = newGroups;
      }
    });

    this.groupCtrl.valueChanges.subscribe((selectedGroupValue) => {
      const selectedGroup = this.groups.find(
        (group) => group.value === selectedGroupValue
      );
      this.subgroups = selectedGroup ? selectedGroup.subgroups : [];
      this.subgroupCtrl.reset();
    });

    this.companiesApiService.getCompanyLicenses().subscribe({
      next: (response) => {
        this.plans = response?.result?.licenses.map((plan) => ({
          ...plan,
          totalLicenses: plan.licensesAvailable,
        }));
      },
      error: (error) => console.log(error),
    });

    this.emailInput.nativeElement.addEventListener(
      "paste",
      this.onPaste.bind(this)
    );
  }

  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  async onPaste(event: ClipboardEvent): Promise<void> {
    event.preventDefault();
    this.emailCtrl.setErrors({ licenses: false, noEmailsPasted: false });
    
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData("text");

    if (pastedText) {
      const pastedEmails = pastedText
        .split("\n")
        .join(",")
        .split(" ")
        .join(",")
        .split(",")
        .map((email) => email.trim())
        .filter(
          (email) =>
            this.isValidEmail(email) &&
            this.emails.findIndex((e) => e.address === email) === -1
        );

      if (!pastedEmails.length) {
        this.emailCtrl.setErrors({ noEmailsPasted: true });
        this.notification.toast(
          "You need to paste emails separated by comma, space or new line.",
          "OK"
        );
        return;
      }

      this.validatingEmails = true;
      
      const response = await lastValueFrom(
        this.usersApiService.validateEmailUniqueness({
          emails: pastedEmails,
          company_id: this.authService.companyId,
        })
      );
      
      this.validatingEmails = false;

      if (response?.result?.exists === true) {
        if (pastedEmails.length === 1) {
          this.notification.toast("This email is already assigned to your company.", "OK");
        } else {
          this.notification.toast("You pasted emails that were already assigned to your company.", "OK");
        }
        return;
      }

      let showedLicensesError = false;
      pastedEmails.forEach((value: string) => {
        const availablePlans = this.plans.filter(
          (plan) => plan.licensesAvailable === 'Unlimited' || plan.licensesAvailable > 0
        );
    
        if (availablePlans.length === 0 && !showedLicensesError) {
          this.emailCtrl.setErrors({ licenses: true });
          this.notification.toast(
            "You don't have licenses available. Contact Administrator.",
            "OK"
          );
          showedLicensesError = true;
        }

        if (!showedLicensesError) {
          this.emailCtrl.setErrors({ licenses: false });
        }
    
        let newPlan: string | null = null;
        if (availablePlans.length === 1) {
          newPlan = availablePlans[0].stripe_id;
        }
        
        this.emails.push({ address: value, plan: newPlan, isSelected: false });
        this.onAssignLicense();
      });

      this.emailCtrl.setValue(null);
    }

    this.emailCtrl.setValue(null);
  }

  onAdd() {
    if (this.emails.length > 0) {
      this.companiesApiService.addUserByEmail({
        users: this.emails.map(user => ({
          email: user.address,
          plan: user.plan
        })),
        group_id: this.groupCtrl.value,
        subgroup_id: this.subgroupCtrl.value
      }).subscribe((response) => {
        if (response?.result?.success) {
          this.notification.toastWithConfig("Users added", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });
          this.dialogRef.close(response.result.users);
        } else {
          this.notification.toastWithConfig(response?.result?.message ?? "Error", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          });
        }
      });
    }
  }

  async add(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.emailCtrl.setValue(value);

    if (this.emailCtrl.errors !== null) {
      return;
    }

    const response = await lastValueFrom(
      this.usersApiService.validateEmailUniqueness({
        emails: [value],
        company_id: this.authService.companyId,
      })
    );
    if (response?.result?.exists) {
      this.emailCtrl.setErrors({ uniqueEmail: true });
      this.notification.toast(
        "This email is already assigned to your account.",
        "OK"
      );
      return;
    } else {
      this.emailCtrl.setErrors({ uniqueEmail: false });
    }

    // Add email

    const availablePlans = this.plans.filter(
      (plan) => plan.licensesAvailable === 'Unlimited' || plan.licensesAvailable > 0
    );

    if (availablePlans.length === 0) {
      this.emailCtrl.setErrors({ licenses: true });
      this.notification.toast(
        "You don't have licenses available. Contact Administrator.",
        "OK"
      );
      return;
    }

    this.emailCtrl.setErrors({ licenses: false });

    let newPlan: string | null = null;
    if (availablePlans.length === 1) {
      newPlan = availablePlans[0].stripe_id;
    }
    this.emails.push({ address: value, plan: newPlan, isSelected: false });
    this.onAssignLicense();

    // Clear the input value
    event.chipInput!.clear();
    this.emailCtrl.setValue(null);
  }

  get canSave(): boolean {
    const hasEmails = this.emails.length > 0;
    const allEmailsHavePlans = this.emails.every((e) => e.plan);
    const hasAvailableLicenses = this.licensesAvailable > 0 || allEmailsHavePlans;

    return hasEmails && allEmailsHavePlans && hasAvailableLicenses;
  }

  get licensesAvailable(): number {
    return this.plans.reduce(
      (total, plan) => total + (plan.licensesAvailable !== 'Unlimited' ? plan.licensesAvailable : 0),
      0
    );
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.onAssignLicense();
    }
  }

  createUniqueEmailValidator(emailArray: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const hasEmail = emailArray.some((obj) => obj.address === value);

      return hasEmail ? { uniqueEmail: true } : null;
    };
  }

  get invalidEmails(): boolean {
    if (!this.emailCtrl.value?.trim()) {
      return false;
    }

    const emails =
      this.emailCtrl.touched || this.emailCtrl.dirty
        ? this.emails?.concat({ address: this.emailCtrl.value })
        : this.emails;

    return emails.some(
      (email) =>
        new FormControl(email?.address?.toLowerCase(), this.emailValidation)
          .invalid
    );
  }

  planLabel(plan: string): string {
    return this.plans.find((planObj) => planObj.stripe_id === plan)?.label;
  }

  onAssignLicense(event?: any): void {
    this.plans.forEach((plan) => {
      let assignedLicenses =
        this.emails.filter((user: any) => user.plan === plan.stripe_id)?.length ??
        0;
      if (plan.totalLicenses !== 'Unlimited') {
        let unassignedLicenses = plan.totalLicenses - assignedLicenses;
        plan.licensesAvailable = unassignedLicenses > 0 ? unassignedLicenses : 0;
      }
    });
  }

  get emailLink(): string {
    const { first_name, last_name, company } = this.authService.userDetails.user_details;
    const body: string = `
    "Hello FlyMSG team"
    I'd like to inquire about adding on _______ more licenses to my account.
    Account name: ${company.name}
    Administrator Requesting: ${first_name} ${last_name}`;

    return `mailto:sales@vengreso.com?subject=${encodeURIComponent(
      "Additional License Inquiry"
    )}&body=${encodeURIComponent(body)}`;
  }

  addNewGroupDialog(): void {
    const dialogHandle = this.dialog.openDialogComponent(
      AddGroupComponent,
      { action: "add-group", class: "portal-admin" },
      "450px"
    );

    dialogHandle.afterClosed().subscribe((group: any) => {
      if (!!group) {
        this.groups = this.groups.concat({
          value: group.group.id,
          displayValue: group.group.name,
          subgroups: [],
        });
      }
    });
  }

  addNewSubGroupDialog(): void {
    const dialogHandle = this.dialog.openDialogComponent(
      AddGroupComponent,
      {
        action: "add-subgroup",
        groupId: this.groupCtrl.value,
        class: "portal-admin",
      },
      "450px"
    );

    dialogHandle.afterClosed().subscribe((subgroup: any) => {
      if (!!subgroup) {
        this.subgroups = this.subgroups.concat({
          value: subgroup.id,
          displayValue: subgroup.name,
        });
      }
    });
  }

  ngOnDestroy(): void {
    let currentUrl = this.router.url;

    this.location.replaceState(currentUrl.replace("/add-by-email", ""));
  }

  onCancel() {
    let currentUrl = this.router.url;
    this.location.replaceState(currentUrl.replace("/add-by-email", ""));
    this.dialogRef.close();
  }
}
