import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, Subscription } from "rxjs";

import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { EditUserComponent } from "../edit-user/edit-user.component";
import { EditGroupComponent } from "../edit-group/edit-group.component";
import { ROLES } from "src/app/core/core-services/constants/roles.constant";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { AdminApiService } from "../services/admin-api.service";
import { NotifyService } from "src/app/core/menu/not.service";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { ClipboardService } from "ngx-clipboard";
import { SuperAdminApiService } from "../services/super-admin-api.service";

@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.scss"],
})
export class GroupComponent implements OnInit, OnDestroy {
  companySlug: string;
  usersPerPage = 100;
  allChecked: boolean = false;
  dataSource: any;
  group: any;
  groupSlug: string;
  groupName: string;
  subgroups:any[] = [];
  search: string = '';
  subgroup: any;
  subGroupSlug: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    "name",
    "subgroup",
    "licenseType",
    "status",
    "role",
    "actions",
  ];
  subscriptions = new Subscription();
  totalUsersCount: number = 0;
  roles: any[];
  roleColors: {
    [role: string]: string;
  } = {
    "Vengreso Admin": "#d3aae5",
    "Global Admin": "#FEEFEA",
    "Group Admin": "#FEF9EA",
    "Reporting Admin": "#EEEEEE",
    User: "#FFF",
  };
  allRoleFilterChecked: boolean = true;
  licenseTypes: any[] = [];
  allLicenseFilterChecked: boolean = true;
  statuses: any = [{label: "Active", checked: true},{label: "Invited", checked: true}];
  selectedSort: string = 'last_added';
  loadingGetGroupUsers: boolean;

  constructor(
    public dialog: DialogService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly adminApiService: AdminApiService,
    private readonly apiService: SuperAdminApiService,
    public notifyService: NotifyService,
    private readonly notification: NotificationService,
    private readonly clipboardService: ClipboardService,
    private readonly config: NgbPopoverConfig
  ) {
    config.triggers = "hover";
    config.container = "body";
    this.companySlug = this.authService.getUserCompanySlug();
    this.roles = ROLES.filter(role => role !== 'Vengreso Admin').map(role => ({ name: role, checked : true }));
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        this.groupSlug = params.get("groupSlug");
        this.subGroupSlug = params.get("subGroupSlug");

        if(this.subGroupSlug === "allusers") {
          this.loadGroup(this.groupSlug);
        } else {
          this.loadSubGroup(this.groupSlug, this.subGroupSlug);
        }

      })
    );
  }

  ngAfterViewInit() {
    this.disableHeaderSorting();
  }

  get isNotAssignedGroup(): boolean {
    return this.groupSlug === "not-assigned" && (!this.group || this.group.group.id === -1);
  }

  loadGroup(slug: string, reloadDataSource: boolean = true) {
    this.displayedColumns = [
      "name",
      "subgroup",
      "licenseType",
      "status",
      "role",
      "actions",
    ];

    if (this.isGroupAdmin) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== "subgroup"
      );
    }

    if (this.isReportingAdmin) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => !["subgroup", "actions"].includes(c)
      );
    }

    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource();
    }
    try {
      this.loadingGetGroupUsers = true;
      this.adminApiService.getGroups().subscribe((response: any) => {
        if (response?.result?.groups) {
          this.group = response.result.groups.find((datum: any) => datum.group.slug === slug);
          if(this.router.url.includes('add-users')){
            this.addUsers(this.group)
          }
      
          if(this.router.url.includes('remove-users')){
            this.removeUsers(this.group)
          }
          this.groupName = this.group?.group?.name;
          this.totalUsersCount = this.group.members;

          if(this.group?.group?.subgroups?.length > 0) {
            this.subgroups = this.group?.group?.subgroups.map((subgroup: any) => ({
              id: subgroup.group.id,
              subgroupName: subgroup.group.name,
              slug: subgroup.group.slug,
              count: subgroup.members,
              checked: true
            }));
          }

          this.subgroups = this.subgroups.concat({
            id: -1,
            subgroupName: 'Not Assigned',
            checked: true,
          });

          this.group.users = this.group?.users?.map((user: any) => ({
            ...user,
            checked: false,
          }));

          if (reloadDataSource) {
            this.dataSource = new MatTableDataSource(this.group.users);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
              return sortHeaderId === 'first_name' ? `${data['first_name']} ${data['last_name']}` : data[sortHeaderId];
            };
            this.licenseTypes = [...new Map(this.group.users.map(user => [user.licenseType, {label: user.licenseType, checked: true}])).values()];
          }
        }
        this.loadingGetGroupUsers = false;
      });
    } catch (error) {
      console.log(error);
    }
  }

  loadSubGroup(groupSlug: string, subGroupSlug: string, reloadDataSource: boolean = true) {
    this.displayedColumns = [
      "name",
      "licenseType",
      "status",
      "role",
      "actions",
    ];
    
    if (this.isReportingAdmin) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => !["actions"].includes(c)
      );
    }
    
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource();
    }
    try {
      this.adminApiService.getGroups(true).subscribe((response: any) => {
        if (response?.result?.groups) {
          const group = response.result.groups.find((datum: any) => datum.group.slug === groupSlug);
          this.group = group;
          this.groupName = group?.group?.name;
          this.groupSlug = groupSlug;
          this.totalUsersCount = group?.members;

          this.subgroups = group?.group?.subgroups.map((subgroup: any) => ({
            id: subgroup.group.id,
            subgroupName: subgroup.group.name,
            slug: subgroup.group.slug,
            count: subgroup.members,
            checked: true
          }));
          
          const subgroup = group?.group?.subgroups.find((record: any) => record.group.slug === subGroupSlug);
          this.subgroup = subgroup;

          if(this.router.url.includes('add-users')){
            this.dialog.openDialogComponent(
              EditGroupComponent,
              { action: "addUsers", data: subgroup, type: 'subgroup', class: 'portal-admin' },
              "650px"
            );
          }
      
          if(this.router.url.includes('remove-users')){
            this.dialog.openDialogComponent(
              EditGroupComponent,
              { action: "removeUsers", data: subgroup, type: 'subgroup', class: 'portal-admin' },
              "550px"
            );
          }

          if (reloadDataSource) {
            const users = subgroup?.users?.map((user: any) => ({
              ...user,
              checked: false,
            }))
            
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
              return sortHeaderId === 'first_name' ? `${data['first_name']} ${data['last_name']}` : data[sortHeaderId];
            };
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  get isVengresoAdmin(): boolean {
    return this.authService.getUserRole().includes("Vengreso Admin");
  }

  get isGlobalOrVengresoAdmin(): boolean {
    return this.authService.getUserRole().some(role => ["Global Admin", "Vengreso Admin"].includes(role));
  }

  get isGlobalAdmin(): boolean {
    return this.authService.getUserRole().includes("Global Admin");
  }

  get isGroupAdmin(): boolean {
    return this.authService.getUserRole().includes("Group Admin");
  }

  get isReportingAdmin(): boolean {
    return this.authService.getUserRole().includes("Reporting Admin");
  }

  filterBySubGroup() {
    const filter = this.subgroups.filter((t:any) => t.checked).map((t:any) => t.subgroupName);

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['subgroup']);
    }
    
    this.dataSource.filter = filter.length > 0 ? filter : this.subgroups.map((t:any) => t.subgroupName);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  someLicenseFilterChecked() {
    return this.licenseTypes.filter((t:any) => t.checked).length > 0 && !this.allLicenseFilterChecked;
  }

  checkLicenseFilterAll(checked: boolean) {
    this.allLicenseFilterChecked = checked;
    this.licenseTypes.forEach((l:any) => (l.checked = checked));
    
    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['licenseType']);
    }
    this.dataSource.filter = this.licenseTypes.map((l:any) => l.label);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateLicenseFilterAllChecked() {
    this.allLicenseFilterChecked = this.licenseTypes.every((l:any) => l.checked);
    const filter = this.licenseTypes.filter((l:any) => l.checked).map((l:any) => l.label);

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['licenseType']);
    }

    if (this.allLicenseFilterChecked || filter.length < 1) {
      this.dataSource.filter = this.licenseTypes.map((t:any) => t.label);
    } else {
      this.dataSource.filter = filter;
    }
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterByStatus() {
    const filter = this.statuses.filter((t:any) => t.checked).map((t:any) => t.label);

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['status']);
    }

    this.dataSource.filter = filter.length > 0 ? filter : this.statuses.map((t:any) => t.label);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  someRoleFilterChecked() {
    return this.roles.filter((t:any) => t.checked).length > 0 && !this.allRoleFilterChecked;
  }

  checkRoleFilterAll(checked: boolean) {
    this.allRoleFilterChecked = checked;
    this.roles.forEach((t:any) => (t.checked = checked));

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['role']);
    }
    this.dataSource.filter = this.roles.map((l:any) => l.name);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateRoleFilterAllChecked() {
    this.allRoleFilterChecked = this.roles.every((t:any) => t.checked);
    const filter = this.roles.filter((t:any) => t.checked).map((t:any) => t.name);

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['role']);
    }

    if (this.allRoleFilterChecked || filter.length < 1) {
      this.dataSource.filter = this.roles.map((t:any) => t.name);
    } else {
      this.dataSource.filter = filter;
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addAction() {
    const dialogRef = this.dialog.openDialogComponent(
      EditGroupComponent,
      { 
        action: "addUsers", 
        data: this.subGroupSlug !== "allusers" ? this.subgroup : this.group, 
        class: 'portal-admin' 
      },
      "650px"
    );

    dialogRef.afterClosed().subscribe((data: any) => {
      if(data == true){
        location.reload();
      }
    });
  }

  editSubgroup(subElement: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditGroupComponent,
      { action: "editSubGroup", data: subElement, class: 'portal-admin' },
      "550px"
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if(!!result){
       location.reload();
      }
    });
  }

  addUsers(element: any): void {
    this.dialog.openDialogComponent(
      EditGroupComponent,
      { action: "addUsers", class: 'portal-admin', type: 'group', data: element },
      "650px"
    );
  }

  removeUsers(element: any): void {
    this.dialog.openDialogComponent(
      EditGroupComponent,
      { action: "removeUsers", class: 'portal-admin', type: 'group', data: element },
      "550px"
    );
  }

  deleteSubgroup(element: any, subElement: any) {
    const dialogRef = this.dialog.openDialogComponent(
      EditGroupComponent,
      { action: "deleteSubGroup", data: subElement, class: 'portal-admin' },
      "550px"
    );

    dialogRef.afterClosed().subscribe((subgroupId: string) => {
      if(!!subgroupId){
        this.subgroups = this.subgroups.filter((sub:any) => sub.id !== subgroupId);
        this.router.navigate([`/${this.companySlug}/admin/groups/${this.toSlug(element.group.name)}/allusers`]);
      }
    });
  }

  addSubGroup() {
    const dialogRef = this.dialog.openDialogComponent(
      EditGroupComponent,
      { 
        action: "addSubGroup", 
        class: 'portal-admin', 
        data: this.group?.group, 
        redirectBaseUrl: `/${this.companySlug}/admin/groups`
      },
      "550px"
    );

    dialogRef.afterClosed().subscribe((group: any) => {
      if(group){
        this.subgroups = this.subgroups.concat({
          id: group.id,
          subgroupName: group.name,
          slug: this.toSlug(group.name),
          count: group?.users?.length,
          checked: true
        });
      }
    });
  }

  assignRole(user:any, role: string): void {
    //ToDo: Check if role is being assigned to Group Admin to determine if to show popup
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data: user, role, action: "assignRole", class: "portal-admin" },
      "640px"
    );

    dialogRef.afterClosed().subscribe((updatedUser: any) => {
      if(!!updatedUser)
      this.dataSource.data = this.dataSource.data.map(
        (user: any) => user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      );
    });
  }

  editProfile(event: MouseEvent, user: any) {
    event.stopPropagation();
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data: user, action: "editProfile", class: "portal-admin" },
      "640px"
    );

    dialogRef.afterClosed().subscribe((updatedUser: any) => {
      if(!!updatedUser)
      this.dataSource.data = this.dataSource.data.map(
        (user: any) => user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      );
    });
  }

  resetPassword(event: MouseEvent, data: any) {
    event.stopPropagation();
    this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "resetPassword", class: "portal-admin" },
      "500px"
    );
  }

  moveTo(event: MouseEvent, data: any) {
    event.stopPropagation();
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "moveTo", class: "portal-admin" },
      "500px"
    );

    dialogRef.afterClosed().subscribe((data: { group_id?: string, subgroup_id?:string, users: any[]}) => {
      if(data.users) {
        const userIds = data.users.map(obj => obj.id);

        this.dataSource.data = this.dataSource.data.filter(user => !userIds.includes(user.id));
        
        if(data.subgroup_id) {
          const subGroup = this.subgroups.find((sub) => sub.id === data.subgroup_id);
          if (subGroup) {
            this.loadSubGroup(this.groupSlug, subGroup.slug, false);
          }
        } else if(data.group_id){
          this.loadGroup(this.groupSlug, false);
        }
      }
    });
  }

  deactivateUser(event: MouseEvent, data: any) {
    event.stopPropagation();
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "deactivateUser", class: "portal-admin" },
      "500px"
    );

    dialogRef.afterClosed().subscribe((deactivatedUsersIds: any) => {
      if(Array.isArray(deactivatedUsersIds) && deactivatedUsersIds.length > 0){
        this.dataSource.data = this.dataSource.data.filter((user: any) => !deactivatedUsersIds.includes(user.id));
      }
    });
  }

  get filtersClear() {
    return this.search.trim().length < 1 && this.selectedSort === 'last_added' &&
    this.statuses.every((t:any) => t.checked === true) &&
    this.licenseTypes.every((t:any) => t.checked === true) &&
    this.roles.every((t:any) => t.checked === true)
  }

  handlePageChange(event: any){
    const previousPageSize = this.usersPerPage;
    const currentPageSize = event.pageSize;

    this.usersPerPage = currentPageSize;

    if (previousPageSize !== currentPageSize) {
      this.allChecked = false;
      this.dataSource.data.forEach((u:any) => (u.checked = false));
    }
  }

  clearFilters() {
    this.search = '';
    this.searchUsers();

    this.sortBy('created_at', 'desc');

    this.allLicenseFilterChecked = true;
    this.licenseTypes.forEach((t:any) => t.checked = true);

    this.statuses.forEach((t:any) => t.checked = true);

    this.allRoleFilterChecked = true;
    this.roles.forEach((t:any) => t.checked = true);
  }

  resetFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data).reduce((currentTerm, key) => {
        return currentTerm + (data as { [key: string]: any })[key] + ' ';
      }, '').toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };    
  }

  searchUsers() {
    this.resetFilterPredicate();
    this.dataSource.filter = this.search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toSlug(text: string) {
    return text
        .toLowerCase()                   
        .trim()                          
        .replace(/[^a-z0-9\s-]/g, '')    
        .replace(/\s+/g, '-')            
        .replace(/-+/g, '-');
  }
  
  customFilterApplied(data: any[]) {
    return data.some((l: any) => l.checked === false)
  }

  sortBy(sortKey: string, direction: string = 'asc') {
    this.dataSource.sort.active = sortKey;
    this.dataSource.sort.direction = direction;
    this.dataSource.sort.sortChange.emit();

    if (sortKey === 'first_name' && direction === 'asc') {
      this.selectedSort = 'a_z';
    } else if (sortKey === 'first_name' && direction === 'desc') {
      this.selectedSort = 'z_a';
    } else if (sortKey === 'created_at' && direction === 'asc') {
      this.selectedSort = 'first_added';
    } else if (sortKey === 'created_at' && direction === 'desc') {
      this.selectedSort = 'last_added';
    }
  }

  disableHeaderSorting() {
    const matSortHeaderElements = document.querySelectorAll('.mat-sort-header-container');
    matSortHeaderElements.forEach(element => {
      (element as HTMLElement).style.cursor = 'default';
      element.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  copyInviteLink(user) {
    this.clipboardService.copyFromContent(user.invitation_link);
    this.notification.toastWithConfig("Invite link copied successfully", null, {
      verticalPosition: "top",
      horizontalPosition: "center",
      panelClass: ["green-notification"],
    });
  }
  
  resendInviteEmail(data) {
    const isBulkOperation = Array.isArray(data) && data.length > 0;

    if (isBulkOperation) {
      const dialogRef = this.dialog.openDialogComponent(
        EditUserComponent,
        { data, action: "resendInvite", class: "portal-admin" },
        Array.isArray(data) ? "600px" : "500px"
      );

      dialogRef.afterClosed().subscribe((data: any) => {
        if (data) {
          this.dataSource.data = this.dataSource?.data.map((user: any) => ({
            ...user,
            checked: false,
          }));
        }
      });
    } else {
      let request: Observable<any>;

      if (
        this.isVengresoAdmin &&
        !this.authService.onCompanyMasqueradeSession
      ) {
        request = this.apiService.resendUserInvitation({
          emails: [data.email],
        });
      } else {
        request = this.adminApiService.resendUserInvitation({
          email: data.email,
        });
      }

      request.subscribe({
        next: () => {
          this.notification.toastWithConfig(
            "Invite email resent successfully",
            null,
            {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["green-notification"],
            }
          );
        },
        error: (error) => console.log(error),
      });
    }
  }
}
